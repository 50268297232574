export default {
    name: 'GoogleMap',
    // props: {
    //     dLocationLatitude: {
    //         type: String,
    //     },
    //     dLocationLongitude: {
    //         type: String,
    //     }
    // },
    data() {
        return {
          center: { lat: 0, lng: 0 },
          currentPlace: null,
          markers: [],
          places: [],
          blockAdd:false,
          zoom:1
        }
      },
      mounted() {
        this.geolocate();
      },
      methods: {
        setPlace(place) {
          this.currentPlace = place;
          this.addMarker();
        },
        addMarker() {
          if (this.currentPlace) {
            
            const marker = {
              lat: this.currentPlace.geometry.location.lat(),
              lng: this.currentPlace.geometry.location.lng(),
            };

            this.$store.commit('setUbicationMap',{
              markers: [],
              place: []
             })

            this.$store.commit('setUbicationMap',{
             markers: marker,
             place: this.currentPlace
            })

            // this.markers.push({ position: marker });
            // this.places.push(this.currentPlace);
            // this.center = marker;
            // this.currentPlace = null;
            // this.blockAdd = true
            // this.zoom = 15
          }
        },
        emptyUbication(){
            this.markers = []
            this.places = []
            this.center.lat = 0
            this.center.lng = 0
            this.blockAdd = false
            document.getElementsByClassName('inputMap')[0].value = ''
            this.zoom=1
        },
        geolocate: function() {
          navigator.geolocation.getCurrentPosition(position => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          });
        },
      },
      computed:{
        // dLocationLatitude(){
        //     return this.$store.state.dLocationLatitude
        // },
        // dLocationLongitude(){
        //     return this.$store.state.dLocationLongitude
        // }
      },
      watch:{
        // markers(){
        //     if (this.markers > 0) {
        //         this.block = true
        //     }
          
        // }
      }
    };